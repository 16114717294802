import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import PageWithHeaderGlobal from "../layouts/PageWithHeaderGlobal/PageWithHeaderGlobal.vue";
import DashboardLayout from "../layouts/DashboardLayout/DashboardLayout.vue";
import notifications from "@/mixins/notifications";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: PageWithHeaderGlobal,
    children: [
      {
        path: "/auth/callback",
        name: "callback",
        component: () => import("@/views/SabiaView.vue"),
        meta: {
          title: "Callback",
        },
      },
      {
        path: "/",
        name: "home",
        component: () =>
          import(/* webpackPrefetch: true */ "@/views/HomeView/HomeView.vue"),
        meta: {
          title: "Home",
        },
      },
      {
        path: "/token/:prevsURL",
        name: "token",

        component: () =>
          import(/* webpackPrefetch: true */ "@/views/TokenView/TokenView.vue"),
        meta: {
          title: "Token",
          requiredAuth: true,
        },
      },
      {
        path: "/nps/:identificador",
        name: "formularioNPS",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/FormularioNPSView/FormularioNPSView.vue"
          ),
        meta: {
          title: "Formulário NPS",
          requiredAuth: false,
        },
      },
      {
        path: "/nps/score/mv",
        name: "scoreNPSMV",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/DashboardNPS/DashboardNPSView.vue"
          ),
        meta: {
          title: "Dashboard NPS",
          requiredAuth: false,
        },
      },

      {
        path: "/npsmv/:identificador",
        name: "formularioNPSMV",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/FormularioNPSMVView/FormularioNPSView.vue"
          ),
        meta: {
          title: "Formulário NPS",
          requiredAuth: false,
        },
      },

      {
        path: "contatosuporte",
        name: "contatosuporte",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/SuporteView/SuporteView.vue"
          ),
        meta: {
          title: "Suporte",
        },
      },
    ],
  },
  {
    path: "/comprovante/:identificador",
    name: "comprovante",
    component: () =>
      import(
        /* webpackPrefetch: true */ "@/views/ComprovanteAgendamentoView/ComprovanteAgendamentoView.vue"
      ),
    meta: {
      title: "Comprovante de Agendamento",
    },
  },
  {
    path: "/autocadastro",
    name: "autocadastro",
    component: () =>
      import(
        /* webpackPrefetch: true */ "@/views/AutocadastroView/AutocadastroView.vue"
      ),
    meta: {
      title: "Autocadastro",
      requiredAuth: true,
    },
  },
  {
    path: "/escolhervinculo",
    name: "escolhervinculo",
    component: () =>
      import(/* webpackPrefetch: true */ "@/views/ChooseView/ChooseView.vue"),
    meta: {
      title: "Escolher vínculo",
      requiredAuth: true,
    },
  },
  {
    path: "/comprovante-solicitacao",
    name: "comprovante-solicitacao",
    component: () =>
      import(
        /* webpackPrefetch: true */ "@/views/ComprovanteView/ComprovanteView.vue"
      ),
    meta: {
      title: "Comprovante",
      requiredAuth: true,
    },
  },
  {
    path: "*",
    name: "notfound",
    component: () =>
      import(
        /* webpackPrefetch: true */ "@/views/Erros/Error404View/Error404View.vue"
      ),
    meta: {
      title: "ERROR 404",
    },
  },
  {
    path: "/sempermissao",
    name: "sempermissao",
    component: () =>
      import(
        /* webpackPrefetch: true */ "@/views/Erros/Error403View/Error403View.vue"
      ),
    meta: {
      title: "ERROR 403",
    },
  },
  {
    path: "/avisoExecutantes",
    name: "avisoExecutantes",
    component: () =>
      import(
        /* webpackPrefetch: true */ "@/views/Erros/AvisoExecutantes/AvisoExecutantesView.vue"
      ),
    meta: {
      title: "Aviso aos Executantes",
    },
  },

  {
    path: "/confirmacao_autocadastro",
    name: "confirmacaoAutocadastro",
    component: () =>
      import(
        /* webpackPrefetch: true */ "@/views/ConfirmacaoAutocadastro/ConfirmacaoAutocadastro.vue"
      ),
    meta: {
      title: "Confirmação de Autocadastro",
      requiredAuth: true,
    },
  },
  {
    path: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "dashboard",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/DashboardView/DashboardView.vue"
          ),
        meta: {
          title: "Dashboard",
          requiredAuth: true,
          authorize: [3, 12],
          //solicitante
        },
      },
      {
        path: "/recepcao",
        name: "recepcao",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/DashboardRecepcao/DashboardRecepcao.vue"
          ),
        meta: {
          title: "Recepção",
          requiredAuth: true,
          authorize: [13],
          //Recepção
        },
      },

      {
        path: "/acompanhamento-solicitacoes",
        name: "acompanhamento-solicitacoes",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/DashboardView/AcompanhamentoSolicitacoesView.vue"
          ),
        meta: {
          title: "Acompanhamento de Solicitações",
          requiredAuth: true,
          authorize: [6, 7, 8],
          //Coordenação estadual de regulação
          //Coordenador(a) de unidade e Coordenador(a) municipal
        },
      },
      {
        path: "/meuperfil",
        name: "meuperfil",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/MeuPerfilView/MeuPerfilView.vue"
          ),
        meta: {
          title: "Meu Perfil",
          requiredAuth: true,
        },
      },
      {
        path: "/lista-vinculos",
        name: "listaVinculos",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/ListaVinculosView/ListaVinculosView.vue"
          ),
        meta: {
          title: "Lista de Vínculos",
          requiredAuth: true,
          authorize: [6, 7, 8],
        },
      },
      {
        path: "/dashboardRecepcao",
        name: "dashboardRecepcao",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/RecepcaoDashboard/RecepcaoDashboardView.vue"
          ),
        meta: {
          title: "Página Inicial",
          requiredAuth: true,
          authorize: [10],
        },
      },
      {
        path: "lista-solicitacao",
        name: "lista",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/SolicitacionList/SolicitacionList.vue"
          ),
        meta: {
          title: "Lista de Solicitações em Andamento",
          requiredAuth: true,
          authorize: [3, 12],
          //solicitante
        },
      },
      {
        path: "escolha-tipo",
        name: "escolhaTipo",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/FormularioSolicitacaoView/EscolhaTipoSolicitacao.vue"
          ),
        meta: {
          title: "Escolha o tipo de solicitação",
          requiredAuth: true,
          authorize: [3, 12],
          //solicitante
        },
      },
      {
        path: "formulario-solicitacao",
        name: "formularioSolicitacao",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/FormularioSolicitacaoView/FormularioSolicitacaoView.vue"
          ),
        meta: {
          title: "Formulário de solicitação",
          requiredAuth: true,
          authorize: [3, 12],
          //solicitante
        },
      },
      {
        path: "formulario-consulta",
        name: "formularioConsulta",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/FormularioSolicitacaoView/FormularioSolicitacaoView.vue"
          ),
        meta: {
          title: "Formulário de Consulta",
          requiredAuth: true,
          authorize: [3, 12],
          //solicitante
        },
      },

      {
        path: "/solicitacoes-encerradas",
        name: "solicitacoesEncerradas",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/SolicitacoesEncerradas/PainelSolicitacoesEncerradas.vue"
          ),
        meta: {
          title: "Solicitações Encerradas",
          requiredAuth: true,
          authorize: [3, 12, 6, 7, 8],
          //solicitante
        },
      },
      {
        path: "agenda",
        name: "agenda",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/AgendaView/AgendaView.vue"
          ),
        meta: {
          title: "Agenda",
          requiredAuth: true,
        },
      },
      {
        path: "/contratroscobertura",
        name: "contratoscobertura",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/ContratosCoberturaView/ContratosCoberturaView.vue"
          ),
        meta: {
          title: "Contratos de cobertura",
          requiredAuth: true,
          authorize: [6, 7],
          //Coordenação estadual de regulação
          //Coordenador(a) de unidade e Coordenador(a) municipal
        },
      },
      {
        path: "/dados-estabelecimento",
        name: "dadosEstabelecimento",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/DadosEstabelecimentoView/DadosEstabelecimentoView.vue"
          ),
        meta: {
          title: "Dados do Estabelecimento",
          requiredAuth: true,
          authorize: [8],
          //Coordenação estadual de regulação
          //Coordenador(a) de unidade e Coordenador(a) municipal
        },
      },
      {
        path: "/consultasespecializadas",
        name: "consultasespecializadas",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/ConsultasEspecializadasView/ConsultasEspecializadasView.vue"
          ),
        meta: {
          title: "Consultas especializadas",
          requiredAuth: true,
          authorize: [6, 7],
          //Coordenação estadual de regulação
          //Coordenador(a) de unidade e Coordenador(a) municipal
        },
      },
      {
        path: "/sigtap",
        name: "sigtap",
        component: () =>
          import(/* webpackPrefetch: true */ "@/views/SigtapView/Sigtap.vue"),
        meta: {
          title: "Sigtap",
          requiredAuth: true,
          authorize: [6],
          //Coordenação estadual de regulação
        },
      },
      {
        path: "/cadastrarpreparo",
        name: "cadastrarpreparo",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/CadastroPreparoView/CadastroPreparoView.vue"
          ),
        meta: {
          title: "Cadastro de preparo",
          requiredAuth: true,
          authorize: [10],
          //Coordenação estadual de regulação
        },
      },
      {
        path: "/adicionarcontrato",
        name: "adicionarcontrato",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/ContratosCoberturaView/FormContratosView.vue"
          ),
        meta: {
          title: "Adicionar contrato",
          requiredAuth: true,
          authorize: [6, 7],
          //Coordenação estadual de regulação
          //Coordenador(a) de unidade e Coordenador(a) municipal
        },
      },
      {
        path: "/painelGrafico",
        name: "painelGrafico",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/PainelGraficoView/PainelGrafico.vue"
          ),
        meta: {
          title: "Painel de Gráficos",
          requiredAuth: true,
          authorize: [6, 7],
          //Coordenação estadual de regulação
          //Coordenador(a) de unidade e Coordenador(a) municipal
        },
      },

      {
        path: "/detalhescontrato/:contrato_id",
        name: "detalhescontrato",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/DetalhesContratoView/DetalhesContratoView.vue"
          ),
        meta: {
          title: "Detalhes do contrato",
          requiredAuth: true,
          authorize: [6, 7],
          //Coordenação estadual de regulação
          //Coordenador(a) de unidade e Coordenador(a) municipal
        },
      },
      {
        path: "/indicadorescontrato",
        name: "indicadorescontrato",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/IndicadoresContratoView/IndicadoresContratoView.vue"
          ),
        meta: {
          title: "Indicadores do contrato",
          requiredAuth: true,
          authorize: [6, 7],
          //Coordenador(a) de unidade e Coordenador(a) municipal
          //Coordenação estadual de regulação
        },
      },
      {
        path: "/escolha",
        name: "escolha",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/EscolhaView/EscolhaView.vue"
          ),
        meta: {
          title: "Escolha",
          requiredAuth: true,
          authorize: [8],
          //Coordenador(a) de unidade e Coordenador(a) municipal
        },
      },
      {
        path: "pendentes-avaliacao",
        name: "pendentesAvaliacao",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/PendentesAvaliacaoView/PendentesAvaliacaoView.vue"
          ),
        meta: {
          title: "Pendentes de Avaliação",
          requiredAuth: true,
        },
      },
      {
        path: "avaliacao",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/AvaliarSolicitacaoView/AvaliarSolicitacaoView.vue"
          ),
        meta: {
          title: "Avaliar",
          requiredAuth: true,
        },
      },
      {
        path: "/regularpaciente",
        name: "regularPaciente",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/RegularPacienteView/RegularPacienteView.vue"
          ),
        meta: {
          title: "Regular Paciente",
          requiredAuth: true,
        },
      },
      {
        path: "/suporte",
        name: "suporteIndex",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/SuporteIndexView/SuporteIndexView.vue"
          ),
        meta: {
          title: "Suporte",
          requiredAuth: true,
          authorize: [1],
          //suporte
        },
      },
      {
        path: "/profissional_referencia",
        name: "profReferenciaIndex",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/PainelProfReferenciaView/DashboardView.vue"
          ),
        meta: {
          title: "Painel de Referencia",
          requiredAuth: true,
          authorize: [9],
          //Profissional de referência
        },
      },
      {
        path: "/profissional_executante",
        name: "profExecutanteIndex",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/PainelProfExecutante/DashboardView.vue"
          ),
        meta: {
          title: "Painel de Executante",
          requiredAuth: true,
          authorize: [11],
          //Profissional de referência
        },
      },

      {
        path: "/gradeunireferencia",
        name: "gradeunireferencia",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/GradeUniReferenciaView/GradeUniReferenciaView.vue"
          ),
        meta: {
          title: "Grade uni-referencia",
          requiredAuth: true,
          authorize: [3],
          //solicitante
        },
      },
      {
        path: "/listaragenda",
        name: "listaragenda",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/ListaAgendaView/ListaAgendaView.vue"
          ),
        meta: {
          title: "Agenda Cadastrada",
          requiredAuth: true,
          authorize: [10], //Responsável agenda
        },
      },
      {
        path: "/cadastraragenda",
        name: "cadastraragenda",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/CadastrarAgenda/CadastrarAgendaView.vue"
          ),
        meta: {
          title: "Cadastro de Agenda",
          requiredAuth: true,
          authorize: [10], //Responsável agenda
        },
      },
      {
        path: "/agendarPacientes/:agenda_id",
        name: "agendarPacientes",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/AgendarView/AgendarView.vue"
          ),
        meta: {
          title: "Agendar Pacientes",
          requiredAuth: true,
          authorize: [10], //Responsável agenda
        },
      },
      {
        path: "/credenciamento",
        name: "credenciamento",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/CredenciamentoView/CredenciamentoView.vue"
          ),
        meta: {
          title: "Credenciamento",
          requiredAuth: true,
        },
      },
      {
        path: "/cadastroFormNPS",
        name: "cadastroFormNPS",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/CadastroFormNPSView/CadastroFormNPSView.vue"
          ),

        meta: {
          title: "Cadastro Formulário NPS",
          requiredAuth: true,
        },
      },

      {
        path: "/listaPesquisasNPS",
        name: "listaPesquisasNPS",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/ListaPesquisaNPSView/ListaPesquisaNPS.vue"
          ),
        meta: {
          title: "Lista de Pesquisas NPS",
          requiredAuth: true,
        },
      },
      {
        path: "/VisualizarPesquisaNPS",
        name: "VisualizarPesquisaNPS",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/VisualizarPesquisaView/VisualizarPesquisaView.vue"
          ),
        meta: {
          title: "Visualizar Pesquisa",
          requiredAuth: true,
        },
      },
      {
        path: "/nps/score/painel_nps",
        name: "painelNPS",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/DashboardNPS-SESA/DashboardNPSView.vue"
          ),
        meta: {
          title: "Dashboard NPS",
          requiredAuth: true,
          authorize: [6, 5, 14], // Coordenação estadual de regulação, Ouvidor(a), Contratualização
        },
      },

      {
        path: "/nps/score/relatorio_aplicacao",
        name: "RelatorioAplicacao",
        component: () =>
          import(
            /* webpackPrefetch: true */ "@/views/RelatorioAplicacaoNPS/RelatorioAplicacaoView.vue"
          ),
        meta: {
          title: "Relatório Aplicação",
          requiredAuth: true,
          authorize: [6, 5], // Coordenação estadual de regulação, Ouvidor(a)
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = `${to.meta.title} - ${process.env.VUE_APP_TITLE}`;
  await store.restored;

  let auth = store.getters["auth/isTokenActive"];
  let { authorize } = to.meta;
  let currentUser = store.getters["auth/getVinculoSelecionado"];

  if (!auth) {
    let authData = store.getters["auth/getAuthData"];
    if (authData.token) {
      try {
        if (
          router.name !== "home" &&
          router.name !== "token" &&
          router.name !== "login" &&
          store.getters["auth/getTokenTokenTotp"].message === "failed"
        ) {
          store.commit("auth/clearUserData");
          next("/");
          notifications.showMessageError({
            message: "Token expirado, faça login novamente",
          });
        }

        auth = true;
        if (authorize) {
          if (authorize.includes(currentUser.perfil_id)) {
            next();
          } else {
            return next({ name: "sempermissao" });
          }
        }
      } catch (error) {
        alert("Token expirado, faça login novamente");
        store.commit("auth/clearUserData");
      }
    }
  }
  if (!auth && to.meta.requiredAuth) {
    return next({ path: "/" });
  }

  next();
});

export default router;
