import { render, staticRenderFns } from "./HeaderGlobal.vue?vue&type=template&id=44472b00&scoped=true&"
import script from "./HeaderGlobal.vue?vue&type=script&lang=js&"
export * from "./HeaderGlobal.vue?vue&type=script&lang=js&"
import style0 from "./HeaderGlobal.vue?vue&type=style&index=0&id=44472b00&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44472b00",
  null
  
)

export default component.exports